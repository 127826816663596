import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";
import Toptag from "../../components/topTag/Toptag";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, styled } from "@mui/material";



const BackPaper =  styled(Paper)({
  height:"580px",
  overflow:"auto",
  padding:"10px",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Term = () => {
    const [state, setState] = React.useState('');
    const navigate = useNavigate();
   const handleChange = data => {
    setState(data)
  };
 
  const updateContent = (e)=> {
    e.preventDefault();
    let obj = { content:state}

    axios.post('https://admin.babytogs.app/admin/add_content?type=2',obj,{headers:{token:sessionStorage.getItem('token')}}).then((res)=>{
      if(res.data.code===200){
          toast.success("Updated!",{
              position: toast.POSITION.TOP_RIGHT
          })
          loadList();
      }else if(res.data.code===203){
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate("/")
        toast.info("Session Expired Please login again!!")
      }
  })
  }


  const loadList = () => {
    axios.get('https://admin.babytogs.app/admin/get_content?type=2',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
    if(res.data.code===200){
      setState(res.data.data.content)   
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
    })
  }

  useEffect(() => {
    loadList()
  }, [])

  return (
    <div className='container-fluid'>
    <Toptag />
    <ToastContainer />
    <BackPaper>
    <EditorToolbar  />
            <ReactQuill
                style={{ height: '470px',overflow:"auto" }}
                theme="snow"
                id='quillState'
                value={state}
                onChange={handleChange}
                placeholder={"Write something..."}
                modules={modules}
                formats={formats}
            />
    <Grid container style={{marginTop:"10px"}}display="flex" justifyContent="flex-start" alignItems="center">
      <Grid item>
        <button className="newBtn"  onClick={updateContent}>SUBMIT</button>
      </Grid>
    </Grid>
    </BackPaper>
</div>
  )
}

export default Term