import React from 'react';
import './toptag.css';
import { useLocation, NavLink as RouterLink } from 'react-router-dom'
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { Grain, Whatshot, Home } from '@mui/icons-material';

function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  })
}

export default function Toptag() {
  let location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <div role="presentation" onClick={(e) => { e.preventDefault(); }}>
      <div className="topNavigator">
        <div className="pTag">
          <Breadcrumbs aria-label='Breadcrumb'>
            {
              pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  
                return last ?
                  (
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' , backgroundColor: '#c8c9ce',
                      borderRadius: '10px',
                      padding: '3px',
                      paddingRight: '12px',
                      fontSize: '15px' , 
                      fontWeight: '600' }}
                      color={value === 'home' ? 'black' : 'black'}
                      key={index}
                    >
                      {value === 'home' ?
                        < Home sx={{ mr: 0.5 }} key={index}  fontSize="inherit" />
                        :
                        < Grain sx={{ mr: 0.5 }}  key={index} fontSize="inherit" />
                      }
                      {toTitleCase(value)}
                    </Typography>
                  )
                  :
                  (
                    <Link color='inherit' component={RouterLink} to={to} key={index} >
                      <Typography color={value === 'home' ? 'black' : '#636363'} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#ced5f4',
                            borderRadius: '10px',
                           boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                            padding: '3px',
                            paddingRight: '12px',
                            textDecoration: 'none',
                            fontSize: '13px',
                            fontWeight: '500',
                            '&.active': {
                              transform: 'scale(0.95)',
                             
                            } }} >
                        {value === 'home' ?
                          < Home sx={{ mr: 0.5, }} key={index} fontSize="inherit" />
                          :
                          < Whatshot  sx={{ mr: 0.5 }} key={index} fontSize="inherit" />
                        }
                        {toTitleCase(value)}
                      </Typography>
                    </Link>
                  )
              })
            }
          </Breadcrumbs>
        </div>
      </div>
    </div>
  )
}