import React, { useEffect, useState } from 'react'
import { AppRegistration, DeleteOutline, DriveFolderUpload, NoteAdd, RemoveRedEye } from '@mui/icons-material'
import { Grid, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import "../item/item.css"
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'


const BackPaper =  styled(Paper)({
  height:"440px",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const AddnewF = () => {
  const navigate = useNavigate();
  const [obj,setObj] = useState({question:'',answer:''})

 
  const addata = (e) => {
    setObj({
        ...obj,
        [e.target.name] : e.target.value.trim(),
     })
  }
 

const addData = (event) =>{
    event.preventDefault();
    axios.post('https://admin.babytogs.app/admin/add_acc',obj,{headers:{token:sessionStorage.getItem('token')}}).then((res)=>{
        if(res.data.code===200){
            toast.success("Successfully added",{
                position: toast.POSITION.TOP_RIGHT
            })
            navigate("/faq")

        }else if(res.data.code===201){
            toast.error("you already added this ",{
                position: toast.POSITION.TOP_RIGHT
            })
            
        }else if(res.data.code===205){
            toast.warning("please add question and answer",{
                position: toast.POSITION.TOP_RIGHT
            })
            
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
    })
}


  return (
    <>
    <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid  container  direction="row"   justifyContent="space-between" alignItems="center">
        <div style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>Add New Faq</div>
         <div>
    
         </div>   
    </Grid>
    </NavPaper>
    
    <BackPaper>
    <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
   <Grid item>Question</Grid>
   <Grid item style={{ width: "100%" }}>
    <textarea type="text" style={{height:"100px"}} name="question"  id="question" onChange={addata} placeholder="Question" className="detailBarInput" />
   </Grid>
   <Grid item>Answer</Grid>
    <Grid item style={{ width: "100%" }}>
    <textarea type="text" style={{height:"100px"}}  name="answer" id="answer" onChange={addata} placeholder="Answer" className="detailBarInput" />
    </Grid>
    <Grid item><button className='newBtn' onClick={addData} >Add</button></Grid>  
   </Grid>
    </BackPaper>
    </>
  )
}

export default AddnewF