import React, { useEffect, useState } from 'react';
import './topbar.css';

import { NavLink, useLocation, useNavigate} from 'react-router-dom';
import { ArrowUpward, Lock, NotificationsNone, Settings } from '@mui/icons-material';
import {  Backdrop, Button, Grid, Menu , MenuItem, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Person , Key, Logout} from '@mui/icons-material';

export default function Topbar() {
    
	const navigate = useNavigate();
    const location = useLocation();
    const [pic,setPic] = useState()
   
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logoutClick = (e) => {
        e.preventDefault();
        toast.success('Logout Successful !', {
            position: toast.POSITION.TOP_RIGHT
        });
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate('/')
        window.location.reload()
        // window.location.reload(true);    
    }
    
    useEffect(()=>{
        setPic(sessionStorage.getItem('image'))
    })
    return (

       <>        
       
        
       <div className="topbarWrapper">

            <div className="logoContainer">
            <NavLink to= '/' className='link'>
                <img src="/images/top-logo.png" alt="logo" className='logoImg'></img>
                <span className="spanName">BabyTogs</span>
                </NavLink>
            </div>
            <div className="topRight">
               
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{shadow: 'none'}}
                >
                    {pic==='blank_pic.png'?
                    <img src='./images/blank_pic.png'  alt="" className='topAvatar' />:
                    <img src={`https://admin.babytogs.app/uploads/${pic}`}  alt="" className='topAvatar' />}

                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <NavLink to='/profile' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Person className='menuIcons'/>Profile</MenuItem>
                        </div>
                    </NavLink>
                    <NavLink to='/change_Password' className='link'>
                        <div>
                    <MenuItem onClick={handleClose}><Key className='menuIcons' />Change Password</MenuItem>
                        </div>
                        </NavLink>
                    <MenuItem onClick={logoutClick}><Logout className='menuIcons'/>Logout</MenuItem>
                    <ToastContainer/>
                </Menu>
            </div>
        </div>
        </>
        
    )
}
