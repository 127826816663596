import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "../user/userDetail.css"


const BackPaper =  styled(Paper)({
  height:"560px",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const FaqDeta = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [det,setDet] = useState([]);
  const [dis,setDis] = useState(true);
  const [obj,setObj] = useState({question:'',answer:''})
  
  
  const addata = (e,i) => {
    // let temp = [...det]
    // temp[i][e.target.name] = e.target.value
    // setDet(temp)
    setObj({
        ...obj,
        [e.target.name] : e.target.value,
     })  
  }
 
  const getAll =()=>{
    axios.get(`https://admin.babytogs.app/admin/get_faq_detail/${location.state.id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          setIsLoading(false)
          setObj(res.data.data[0])
        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 
    const updateFaq =()=>{
      axios.post(`https://admin.babytogs.app/admin/edit_faq_detail/${location.state.id}`,obj,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
          if(res.data.code===200){
            setIsLoading(false)
            setDis(true)
            toast.success("Updated successfully!")
          }else if(res.data.code===201){
            toast.error("error are coming")
          }else if(res.data.code===203){
            localStorage.clear('token');
            sessionStorage.clear('token');
            navigate("/")
            toast.info("Session Expired Please login again!!")
          }
        })
      } 
    useEffect(()=>{
      setIsLoading(true)
      getAll()
    },[])
  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
    <ToastContainer />
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>FAQ</Grid>
      {dis?<Grid item><button className='newBtn'  onClick={()=>{setDis(false)}}>Edit</button></Grid>:null}  
    </Grid>
    <hr></hr>
      <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item>Question</Grid>
      <Grid item style={{ width: "100%" }}>
      <textarea type="text" style={{height:"120px"}} disabled={dis?true:false} value={obj.question}  onChange={addata} name="question" id="question" className="detailBarInput" />
      </Grid>
      <Grid item>Answer</Grid>
      <Grid item style={{ width: "100%" }}>
        <textarea type="text" style={{height:"180px"}} disabled={dis?true:false}    value={obj.answer}  onChange={addata} name="answer" id="answer" className="detailBarInput" />
      </Grid>
      {!dis?<Grid item><button className='newBtn' onClick={updateFaq}>Update</button></Grid>:null}  
      </Grid>
    </BackPaper>
    </>
    }
   
    </>
  )
}

export default FaqDeta

