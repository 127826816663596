import './login.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/loader/Loader';


export default function Login() {
	const navigate = useNavigate();
	const [style, setStyle] = useState("container1 left-panel-active");
	const [login, setLogin] = useState({
		email: "",
		password: "",
	});
	const [forgot,setForgot] = useState()
	const [message,setMessage] = useState(false)

	const changeStyle1 = () => {
		setStyle("container1 right-panel-active");
	};
	const changeStyle2 = () => {
		setStyle("container1 left-panel-active");
	};

	const handleKeyDown = e => {
		if (e.key === " ") {
			e.preventDefault();
		}
	};

	const inputEvent = (e) => {
		const { id, value } = e.target;
		setLogin((prevalue) => {
			return {
				...prevalue,
				[id]: value
			};
		});
	};

	const submitForm = (event) => {
		event.preventDefault();
		let obj = { ...login };

		axios.post('https://admin.babytogs.app/admin/login', obj).then((res) => {
			if (res.data.code===200) {
				sessionStorage.setItem('image',res.data.profile_pic)
				console.log('in')
				toast.success('Logged in !', {
					position: toast.POSITION.TOP_RIGHT
				});
				sessionStorage.setItem('token',res.data.x_token)
				navigate('/home')
				window.location.reload();
			} else if(res.data.code===201 || res.data.code===202){
				toast.info('Invalid Credentials!', {
					position: toast.POSITION.TOP_RIGHT
				});
			}


		}).catch((err) => {
			if(err.message==='Request failed with status code 500'){
				toast.error('Wrong Credentials!', {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			console.log(err.message)
		})
	};

	const submitFormForgotPass = (event) => {
		event.preventDefault();
		axios.post('https://admin.babytogs.app/admin/forgot_password', forgot).then((res) => {
			console.log(res,"dsfygyku")
			if (res.data.code===200) {
				setMessage(true)
			} else if  (res.data.code===201){
				toast.error('Wrong Credentials!', {
					position: toast.POSITION.TOP_RIGHT
				});
			}else if  (res.data.code===205){
				toast.info('email is required!', {
					position: toast.POSITION.TOP_RIGHT
				});
			}

		}).catch((err) => {
			console.log(err, "err")
			window.alert(err.message)
		})
	};



	return (
		<>
			<ToastContainer autoClose={1000}/>
			<div className="fill_center">
				<div className={style} id="container">

					<div className="form-container sign-up-container">
						<form onSubmit={submitFormForgotPass}>
							{message===true?(<>
							<div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
							<div className='message'>Email Verified Successfully!</div>
							<div className='paraMessage'>Please check your email we send you one time password!</div>
							</div>
							</>):(<>
							<img src="/images/1024.png" alt="" className='loginLogo' />
							<h1 className='heading2'>Recover Account</h1>
							<input type="email" id='email' onKeyDown={handleKeyDown}  onChange={(e)=>setForgot({email:e.target.value})} required={true} placeholder="Email" />
							<button className='submitButton' >Submit</button>
							</>
							)}
							
						</form>
					</div>

					<div className="form-container sign-in-container">
						<form onSubmit={submitForm}>
							<img src="/images/1024.png" alt="" className='loginLogo' />
							<h1 className='heading1'>SIGN IN</h1>
							<input type="email" id='email' onKeyDown={handleKeyDown}  onChange={inputEvent} required={true} placeholder="Email" />
							<input type="password" id='password' onKeyDown={handleKeyDown}  onChange={inputEvent} required={true} placeholder="Password" />
							<button className='singinButton' >Sign In</button>
						</form>
					</div>

					<div className="overlay-container">
						<div className="overlay">
							<div className="overlay-panel overlay-left">
								<h1>Hello, Friend!</h1>
								<p>To keep connected with us please login with your personal info</p>
								<button className="ghost" id="signIn" onClick={changeStyle2}>Sign In</button>
							</div>
							<div className="overlay-panel overlay-right">
								<h1>Welcome Back!</h1>
								<p>To recover your account password, Click below!</p>
								<button className="ghost" id="signUp" onClick={changeStyle1}>Forgot Password?</button>
							</div>
						</div>
					</div>
				</div>
			</div>



			
		</>
	)
}

