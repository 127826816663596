import './card.css';
import { PermIdentity, Person4, Timeline, Store, Checkroom, Public, Hail } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import Toptag from '../../components/topTag/Toptag';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';




export default function Card() {
    const navigate = useNavigate();
    const [user,setUser] = useState()
    const [region,setRegion] = useState()

    const getAll = ()=>{
        axios.get('https://admin.babytogs.app/admin/get_count',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        setUser(res.data.users)
            setRegion(res.data.regions)
        if(res.data.code===200){
             
            }else if(res.data.code===201){
              toast.error("error are coming for fetching images")
            }else if(res.data.code===203){
              localStorage.clear('token');
              sessionStorage.clear('token');
              navigate("/login-account")
              toast.info("Session Expired Please login again!!")
            }
           
          })
        } 
        useEffect(()=>{
          getAll()
        },[])
    return (<>
            <Toptag />
    <div className="container-fluid">
         
         <div className="cardWrapper">
             <NavLink to='/home/users' className='link'>
                 <div className="cardContainer red">
                     <div className="cardTopLogo ">
                         <Person4 className='cardLogo green' />
                     </div>
                     <span className='cardTitle green'>Users</span>
                     <span className='userCount'><Timeline className='trendingLogo' />{user}</span>
                 </div>
             </NavLink>
             {/* <NavLink to='/home/country-region' className='link'>
                 <div className="cardContainer orange">
                     <div className="cardTopLogo">
                         <Public className='cardLogo pink' />
                     </div>
                     <span className='cardTitle pink'>Region</span>
                     <span className='userCount'><Timeline className='trendingLogo' />{region}</span>

                 </div>
             </NavLink> */}
             
         </div>
     </div>

     
    
    </>
   

    )
}

