import React, { useEffect, useState } from 'react'
import { AppRegistration, DeleteOutline, DriveFolderUpload, NoteAdd, RemoveRedEye } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Grid, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import "../item/item.css"
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'


const BackPaper =  styled(Paper)({
  height:"580px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Faq = () => {
  const [all,setAll]= useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [deleteopen, setDelOpen] = React.useState(false);
  const navigate = useNavigate();
  const [id,setId] = useState()


  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };
 
  const getAll =()=>{
    axios.get('https://admin.babytogs.app/admin/get_faq',{headers:{"token":sessionStorage.getItem('token')}}).then((res) => {
    if(res.data.code===200){
      setIsLoading(false)
      setAll(res.data.data)   
        }else if(res.data.code===201){
            toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
    })
    } 
  useEffect(()=>{
    setIsLoading(true)
    getAll()
  },[])



const deleteOne = (id) =>{
  axios.delete(`https://admin.babytogs.app/admin/delete_acc/${id}`,{headers:{"token":sessionStorage.getItem('token')}}).then((res) => {
      if(res.data.code===200){
        getAll()  
        setDelOpen(false);
        toast.success("Deleted Successfully!")
      }else if(res.data.code===201){
        toast.error("error are coming")
      }else if(res.data.code===203){
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate("/")
        toast.info("Session Expired Please login again!!")
      }
    })
}

  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid  container  direction="row"   justifyContent="space-between" alignItems="center">
        <div></div>
         <div>
         <Grid container spacing={3} direction="row"  justifyContent="flex-start" alignItems="center">
          <Grid item><button className='newBtn' onClick={()=>navigate("/add-new-faq")}>Add New</button></Grid>  
         </Grid>
         </div>   
    </Grid>
    </NavPaper>
    
    <BackPaper>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow className='tableHead'>
            <TableCell>S.No</TableCell>
            <TableCell>Question</TableCell>
            <TableCell>Answer</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {all.map((element,index)=>{
            let date = new Date(element.created_at);
            let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
            let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
              datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
              date.toISOString().substring(0, 10).split("-")[0];
            return(
          <TableRow key={index+1} className='tableData'>
            <TableCell>{index+1}</TableCell>
            <TableCell>{element.question.slice(0,30)}</TableCell>
            <TableCell>{element.answer.length>200?<>{element.answer.slice(0,200)}....</>:element.answer}</TableCell>
            <TableCell align="center">{newDate}</TableCell>
            <Grid  container spacing={2} style={{marginTop:"10px"}}direction="row" justifyContent="center" alignItems="center">
               <Grid item>
                 <Tooltip title="view faq detail" >
                 <NavLink to='/faq/details' state={{ id: element._id }}><RemoveRedEye className='visibilityIc' /></NavLink>
                 </Tooltip>
               </Grid>
               
               <Grid item>
                 <Tooltip title="Delete Faq" >
                  <DeleteOutline className="userListDelete" onClick={()=>{handleDeleteOpen(element._id)}} />
                 </Tooltip>
               </Grid>
              <Grid item></Grid>
              </Grid>
          </TableRow>
            )
        })}    
        </TableBody>
      </Table>
      <Dialog open={deleteopen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this faq"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteOne(id)}} autoFocus>Delete</Button>
              </DialogActions>
            </Dialog>
    </BackPaper>
    </>
    }
   
    </>
  )
}

export default Faq