import { Block, DeleteOutline, RemoveRedEye } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Slide, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./item.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper =  styled(Paper)({
  borderRadius:"0",
  height:"580px",
  overflow:"auto",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Default = () => {
  const location = useLocation();
  const [reg, setReg] = useState('UK');
  const [all,setAll] = useState([]);
  const [sall,setSAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [id,setId] = useState()
  const [deleteopen, setDelOpen] = React.useState(false);


  const handleChange = (e) => {
    setReg(e.target.value);
  };
  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };

  const getAll =()=>{
    axios.get('https://admin.babytogs.app/admin/get_region',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
    console.log(res,"flibh")    
    if(res.data.code===200){
          setAll(res.data.data)  
        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }
        else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 

    const getAllSize =()=>{
      axios.get(`https://admin.babytogs.app/admin/get_sizes?region=${reg}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        console.log(reg,"lkrgbhub")
          if(res.data.code===200){
            setIsLoading(false)
           setSAll(res.data.data)  
          }else if(res.data.code===201){
            toast.error("error are coming for fetching images")
          }else if(res.data.code===203){
            localStorage.clear('token');
            sessionStorage.clear('token');
            navigate("/")
            toast.info("Session Expired Please login again!!")
          }
        })
      } 
      useEffect(()=>{
        setIsLoading(true)
        getAllSize()
        getAll()
      },[reg])


      const deleteSize = (id) =>{
        axios.delete(`https://admin.babytogs.app/admin/delete_size/${id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
            if(res.data.code===200){
              setDelOpen(false);
              getAllSize();
              toast.success("Deleted Successfully!")
            }else if(res.data.code===201){
              toast.error("Error While Deleting Size")
            }else if(res.data.code===203){
              localStorage.clear('token');
              sessionStorage.clear('token');
              navigate("/")
              toast.info("Session Expired Please login again!!")
            }
          })
      }
    
  return (
    <>
    {isLoading?(
      <Loader />

    ):(<>
    <Toptag />
    <ToastContainer />

    <NavPaper>
    <Grid container spacing={3} direction="row"  justifyContent="space-between"  alignItems="center">
      <Grid item>
      <Grid container spacing={2} direction="row" justifyContent="flex-start"  alignItems="center">
        <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>Filter By Region</Grid>
        <Grid item>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard-label"
          value={reg}
          onChange={handleChange}
        >
         {all.map((element,index)=>(
          <MenuItem key={index+1}  value={element.regions}>{element.regions}</MenuItem>
         ))} 
        </Select>
      </FormControl>
        </Grid>
      </Grid>
      </Grid>
      {/* <Grid item><button className='newBtn' onClick={()=>navigate("/add-new-entry")}>Add More</button></Grid> */}
    </Grid>
    </NavPaper>
    <BackPaper>
      {sall.length===0 && reg ?(
        <Grid container spacing={2} style={{marginTop:"30px"}} direction="column" justifyContent="space-evenly" alignItems="center">
          <Grid item><img src="/images/no-data.png" alt="error-image" style={{height:"200px",width:"250px"}}/></Grid>
          <Grid item><div className="errorMessage">Empty size and age.</div></Grid>
          <Grid item><button className='newBtn' onClick={()=>navigate("/add-new-entry")}>Create Now</button></Grid>
        </Grid>
      ):(
    <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Size</TableCell>   
            <TableCell>Age</TableCell>
            <TableCell>Region</TableCell>
            <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sall.map((element,index)=>{
              let date = new Date(element.created_at);
              let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
              let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
                date.toISOString().substring(0, 10).split("-")[0];
              return(
                <TableRow key={index+1}>
                <TableCell>{newDate}</TableCell>
                <TableCell>{element.size}</TableCell>
                <TableCell>{element.age}</TableCell>
                <TableCell>{element.region}</TableCell>
                <TableCell align="right">
                <Grid  container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                  <DeleteOutline className='Icon' onClick={()=>{handleDeleteOpen(element._id)}}/>
                  </Grid>
                </Grid>
                </TableCell>
                </TableRow>
              )
            })}
        
          </TableBody>
        </Table>
        )}
        <Dialog open={deleteopen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this size and age?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteSize(id)}} autoFocus>Delete</Button>
              </DialogActions>
            </Dialog>
    </BackPaper>
    </>


    )
     
    }
    
 
    </>
  )
}

export default Default


