import React from 'react'
import './sidebar.css';

import { NavLink} from 'react-router-dom';


import {Home,QueryStats,Category,AccountCircle,TrendingUp,Message,Feedback,Email,Storefront,Leaderboard,CurrencyBitcoin, LocalGroceryStore, LocalMall, Loyalty, Flag, Policy, FindInPage, ReportProblem, Inventory, ProductionQuantityLimits, ChildFriendly, Checkroom, Contacts, AdUnits, LiveHelp, DisabledByDefault, Public, FormatSize, Gavel} from '@mui/icons-material';


export default function Sidebar() {
  return (
    <div className='sidebar'>
        <div className="sidebarWrapper">

            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Dashboard</h3>
                <ul className="sidebarList">
                    <NavLink to= '/home' className='link'>
                    <li className="sidebarListItem">
                    <Home className='sidebarIcon '/>
                    Home
                    </li>
                    </NavLink>
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">User Management</h3>
                <ul className="sidebarList">

                    <NavLink to='/users' className='link'>
                    <li className="sidebarListItem">
                    <AccountCircle className='sidebarIcon'/>
                    Users
                    </li>
                    </NavLink>
                    
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Default Item Management</h3>
                <ul className="sidebarList">
                <NavLink to='/add-size' className='link'>
                    <li className="sidebarListItem">
                    <FormatSize className='sidebarIcon'/>
                    Add Sizes
                    </li>
                </NavLink>
                <NavLink to='/default-size' className='link'>
                    <li className="sidebarListItem">
                    <DisabledByDefault className='sidebarIcon'/>
                    Default Listing 
                    </li>
                </NavLink>
                <NavLink to='/item-images' className='link'>
                    <li className="sidebarListItem">
                    <Category className='sidebarIcon'/>
                    Items
                    </li>
                </NavLink>
                </ul>
            </div>
            <div className="sidebarMenu">
                <h3 className="sidebarTitle">Content Management</h3>
                <ul className="sidebarList">
                    <NavLink to='/faq' className='link'>
                    <li className="sidebarListItem">
                    <LiveHelp className='sidebarIcon'/>
                    Help
                    </li>
                    </NavLink>
                    <NavLink to='/privacy-policy' className='link'>
                    <li className="sidebarListItem">
                    <Policy className='sidebarIcon'/>
                    Privacy Policy
                    </li>
                    </NavLink>
                    <NavLink to='/terms-&-conditions' className='link'>
                    <li className="sidebarListItem">
                    <Gavel className='sidebarIcon'/>
                    Term & Conditions
                    </li>
                    </NavLink>
                </ul>
            </div>
            
        </div>
    </div>

  )

}
