import { Autocomplete, FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Toptag from '../../components/topTag/Toptag'
import countryCodes  from "country-codes-list"
import Loader from '../../components/loader/Loader'
import { DeleteOutline } from '@mui/icons-material'


const NavPaper =  styled(Paper)({
    padding:"20px",
    borderRadius:"0",
    backgroundColor:"white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
  })
  
  const BackPaper =  styled(Paper)({
    borderRadius:"0",
    height:"530px",
    overflow:"auto",
    backgroundColor:"white",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
  })
const AddNew = () => {
  const [data,setData] = useState({age:'',size:'',region:''});
  const [all,setAll] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sall,setSAll] = useState([]);
  const [reg, setReg] = useState("");

  
    
const addData = (e) =>{
  setData({
    ...data,
    [e.target.name] : e.target.value.trim()
 })
} 

const getAllSize =()=>{
      axios.get(`https://admin.babytogs.app/admin/get_sizes?region=${reg}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        console.log(reg,"lkrgbhub")
          if(res.data.code===200){
            setIsLoading(false)
           setSAll(res.data.data)  
          }else if(res.data.code===201){
            toast.error("error are coming for fetching images")
          }else if(res.data.code===203){
            localStorage.clear('token');
            sessionStorage.clear('token');
            navigate("/")
            toast.info("Session Expired Please login again!!")
          }
        })
      } 
      useEffect(()=>{
        getAllSize()
      },[reg])

  const addSize = () =>{
    axios.post('https://admin.babytogs.app/admin/add_size',data,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){   
        toast.success("Add Successfully!")
          setReg(data.region)
          setData(prevState => ({...prevState, age:'', size:'', region:''}));
        }else if(res.data.code===205){
          toast.info("Please Enter All Values")
        }else if(res.data.code===201){
          toast.error("Page is not responding please wait!")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
  }
  const myCountryCodesObject = countryCodes.customList()
  const regions = ["UK", "US", "EU"];
  const regionOptions = regions.map((region) => ({
    label: region,
    value: region,
  }));
  const options = Object.keys(myCountryCodesObject).map((code) => ({
    label: code,
    value: code,
  })).concat(regionOptions);
  return (
    <>
     <Toptag />
     <ToastContainer />

    <NavPaper>
    <Grid container spacing={3} direction="row"  justifyContent="space-between"  alignItems="center">
      <Grid item>
      <Grid container spacing={2} direction="row" justifyContent="flex-start"  alignItems="center">
        <Grid item style={{fontSize:"17px",fontWeight:"bold"}}>Add New Size</Grid>
        <Grid item>
        
        </Grid>
      </Grid>
      </Grid>
      <Grid item></Grid>
    </Grid>
    <Grid  container spacing={2} style={{marginTop:"20px",marginBottom:"20px"}} direction="row"  justifyContent="center" alignItems="center">
       <Grid item>
            <Grid  container spacing={2} direction="row"  justifyContent="flex-start" alignItems="center">
            <Grid item style={{fontSize:"17px",fontWeight:"bold"}}>Age</Grid>
            <Grid item><input type="text" name="age" placeholder='age'  id="age" className="searchBarInput" onChange={addData} value={data.age}/></Grid>
            </Grid>
       </Grid>
       <Grid item>
            <Grid  container spacing={2} direction="row"  justifyContent="flex-start" alignItems="center">
            <Grid item style={{fontSize:"17px",fontWeight:"bold"}}>Size</Grid>
            <Grid item><input type="text" name="size" placeholder='size'  id="size" className="searchBarInput" onChange={addData} value={data.size}/></Grid>
            </Grid>
       </Grid>
       <Grid item>
            <Grid  container spacing={2} direction="row"  justifyContent="flex-start" alignItems="center">
            <Grid item style={{fontSize:"17px",fontWeight:"bold"}}>Select Region</Grid>
            <Grid item>
              
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) === 0 &&
                  (regions.includes(option.value) ||
                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
              )
            }
            getOptionLabel={(option) => option.label || ''}
              sx={{ width: 300 }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => setData({...data, region: newValue.value})}
              renderInput={(params) => <TextField {...params} label="Search region" variant='standard' />}
            />
            </Grid>
            </Grid>
       </Grid>
       <Grid item>
       <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div><button className='newBtn' onClick={addSize}>Add</button></div>
    </div>
       </Grid>
     
    </Grid>
    </NavPaper>
    <BackPaper>
    {sall.length===0?(
              <Grid container spacing={2} style={{marginTop:"30px"}} direction="column" justifyContent="center" alignItems="center">
              <Grid item><img src="/images/no-data.png" alt="error-image" style={{height:"150px",width:"200px"}}/></Grid>
              <Grid item><div className="errorMessage">Date not found!</div></Grid>
            </Grid>
            ):(
              <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>Size</TableCell>   
                <TableCell>Age</TableCell>
                <TableCell>Region</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sall.map((element,index)=>{
                  let date = new Date(element.created_at);
                  let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
                  let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                    datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
                    date.toISOString().substring(0, 10).split("-")[0];
                  return(
                    <TableRow key={index+1}>
                    <TableCell>{newDate}</TableCell>
                    <TableCell>{element.size}</TableCell>
                    <TableCell>{element.age}</TableCell>
                    <TableCell>{element.region}</TableCell>
                    </TableRow>
                  )
                })}
            
              </TableBody>
            </Table>
        

            )}
 
    </BackPaper>
    </>
  )
}

export default AddNew