import { Block, DeleteOutline, DoneAll, RemoveRedEye, Search } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"



const NavPaper =  styled(Paper)({
  padding:"10px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper =  styled(Paper)({
  height:"580px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const User = () => {
  const [all,setAll] = useState([]);
  const [user,setUser] = useState()
  const navigate = useNavigate();
  const [row,setRow] = useState('10');
  const [key,setKey] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteopen, setDelOpen] = React.useState(false);
  const [id,setId] = useState()
  const [status,setStatus] = useState()
  const [skip,setSkip] = useState('0')

  const handleBlockOpen = (id,status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };

const searchHandle = (event) =>{
  setKey(event.target.value)
}

const getCount = ()=>{
  axios.get('https://admin.babytogs.app/admin/get_count',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
      setUser(res.data.users)
  if(res.data.code===200){
       
      }else if(res.data.code===201){
        toast.error("error are coming for fetching images")
      }else if(res.data.code===203){
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate("/login-account")
        toast.info("Session Expired Please login again!!")
      }
     
    })
  } 

  const getAll = ()=>{
    if(key){
      setIsLoading(false)   
    }
    axios.get(`https://admin.babytogs.app/admin/get_all_user?value=${key}&row=${row}&skip=${skip}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
    if(res.data.code===200){
      setIsLoading(false)   
      setAll(res.data.data) 

        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/login-account")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 
    useEffect(()=>{
      if(key==="" && row==="" && skip ===""){
        setIsLoading(true)
      }
      
      getCount()
      getAll()
    },[key,row,skip])
 
      const deleteUser = (id) =>{
        axios.delete(`https://admin.babytogs.app/admin/delete_user/${id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
            if(res.data.code===200){
              getAll();  
              setDelOpen(false);
              toast.success("Deleted Successfully!")
            }else if(res.data.code===201){
              toast.error("error are coming")
            }else if(res.data.code===203){
              localStorage.clear('token');
              sessionStorage.clear('token');
              navigate("/")
              toast.info("Session Expired Please login again!!")
            }
          })
      }
      const BlockUser = (id) =>{
        axios.post(`https://admin.babytogs.app/admin/block_user/${id}`,{},{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
              
        if(res.data.code===200){
          getAll();  
          setOpen(false);
          toast.success("Successfully updated!");
             
            }else if(res.data.code===201){
              toast.error("error are coming")
            }else if(res.data.code===203){
              localStorage.clear('token');
              sessionStorage.clear('token');
              navigate("/")
              toast.info("Session Expired Please login again!!")
            }
          })
      }

      const handleChange = (e) => {
        if(e.target.value===user){
          setSkip(0)
          setRow(user)
        }else{
          setRow(e.target.value)
        }
      };
      const skipPagination = (e, page) =>{
        setSkip((page - 1) * row)
      }
      console.log(typeof(user))
  return (
        <>
        {
        isLoading?(
        <Loader />):(
          <>
           <Toptag />
      <ToastContainer />
      <NavPaper>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <div></div>
          <div>
            <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
            <div style={{marginRight:"25px",fontSize:"15px",fontWeight:"400",color:"grey"}}>Total Users:- {user}</div>
            <div style={{marginRight:"25px",fontSize:"15px",fontWeight:"400",color:"grey"}}>Row Per Page:- </div>
            <div style={{marginRight:"25px",fontSize:"15px",fontWeight:"400",color:"grey"}}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
             <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard-label"
                value={row}
                onChange={handleChange}
              >
              <MenuItem  value="10">10</MenuItem> 
              <MenuItem   value="20">20</MenuItem> 
              <MenuItem   value="30">30</MenuItem> 
              <MenuItem  value={user}>All</MenuItem> 
              </Select>
            </FormControl>
            </div>
            <div>
            <div className='searchBar'>
              <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext'  className='searchBarInput' />
              <Search className='searchIcon' />
            </div>
            </div>
            </div>
           
          </div>
     </div>
     </NavPaper>
     <BackPaper>
      {all.length===0 && key?
      <Grid container spacing={2} style={{marginTop:"30px"}} direction="column" justifyContent="space-evenly" alignItems="center">
      <Grid item><img src="/images/no-data.png" alt="error-image" style={{height:"150px",width:"200px"}}/></Grid>
      <Grid item><div className="errorMessage">User not found!</div></Grid>
    </Grid>:
    <>
    <Table stickyHeader aria-label="sticky table">
           <TableHead>
             <TableRow>
             <TableCell>User</TableCell>
             <TableCell >Name</TableCell>
             <TableCell >Email</TableCell>
             <TableCell>Joined Since</TableCell>
             <TableCell align="center">Verification Status</TableCell>
             <TableCell>Active Status</TableCell>
             <TableCell align="center">Action</TableCell>
             </TableRow>
             </TableHead>
             <TableBody>
            
            {all.map((element,index)=>{
              
              let date = new Date(element.created_at);
              let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
              let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
                date.toISOString().substring(0, 10).split("-")[0];
              return(<>
             <TableRow  key={index+1}>
             <TableCell>
              {element.profile_pic==='blank_pic.png'?(
                                <img src="/images/blank_pic.png" style={{width:"30px",height:"30px",borderRadius:"50px"}} alt="" />
                ):(
                  <img src={`https://admin.babytogs.app/uploads/${element.profile_pic}`} style={{width:"30px",height:"30px",borderRadius:"50px"}} alt="" />

                )}
             </TableCell>
             <TableCell>{element.name}</TableCell>
             <TableCell>{element.email}</TableCell>
             <TableCell>{newDate}</TableCell>
             <TableCell align="center">
              {element.emailVerified===true?(
                <Chip label="Verified" className="statusChipVerified" />
              ):(
                <Chip label="Pending Verificaton" className="statusChip" />
              )}</TableCell>
             <TableCell>
              {element.isActive===true?(
              <Chip label="Active" className="statusChipActive" />)
              :(
              <Chip label="Blocked" className="statusChipBlock" />) 
              }</TableCell>
             <TableCell align="right">
             <Grid  container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
               <Grid item>
                 <Tooltip title="view profile" >
                 <NavLink to='/users/details' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc'   /></NavLink>
                 </Tooltip>
               </Grid>
               <Grid item>
                 <Tooltip title={element.isActive ? 'block user' : 'unblock user'} >
                 {element.isActive===true?
                 <Block  className="blockIcon"  style={{color:"green"}} onClick={()=>{handleBlockOpen(element._id,element.isActive)}}/>
                 :
                 <Block  className="blockIcon"  style={{color:"red"}} onClick={()=>{handleBlockOpen(element._id,element.isActive)}} />
                 } 
                 </Tooltip>
               </Grid>
               <Grid item>
                 <Tooltip title="Delete User" >
                  <DeleteOutline className="userListDelete" onClick={()=>{handleDeleteOpen(element._id)}}/>
                 </Tooltip>
               </Grid>
              <Grid item></Grid>
              </Grid>
              </TableCell>
              </TableRow>
              </>
              )
              
              })}
            </TableBody>
            </Table>
        
    </>
      }
      <Dialog open={open}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {status===false?("Are you sure want to unblock this user?"):("Are you sure want to block this user?")}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleBlock}>Cancel</Button>
                <Button onClick={()=>{BlockUser(id)}} >{status===false?'unBlock':'Block'}</Button>
              </DialogActions>
            </Dialog>
            
    <Dialog open={deleteopen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this user?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteUser(id)}} >Delete</Button>
              </DialogActions>
            </Dialog>
            </BackPaper>   
            <div style={{display:'flex',flexDirection:"row",justifyContent:"center",alignItems:'center',marginTop:"14px",marginBottom:"14px"}}>
              <div>
              <Pagination count={Math.ceil(user/row)} page={Math.floor(skip/row)+1} variant="outlined" onChange={skipPagination}shape="rounded" />
              </div>
            </div>
          </>

         )}
     
    </>
  )
}

export default User