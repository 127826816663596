import { Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Toptag from '../../components/topTag/Toptag'
import "./userDetail.css"


const BackPaper =  styled(Paper)({
  height:"600px",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const UserDetail = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [det,setDet] = useState([]);
  const [obj,setObj] = useState({name:''})
  const [dis,setDis] = useState(true);

 
  const addata = (e) => {
    setObj({
        ...obj,
        [e.target.name] : e.target.value,
     })

  }
  const getAll =()=>{
    axios.get(`https://admin.babytogs.app/admin/get_user_detail/${location.state.id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          setObj(res.data.data[0])  
        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 

    const updateUser =()=>{
      axios.post(`https://admin.babytogs.app/admin/edit_user_detail/${location.state.id}`,obj,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          setDis(true)
          toast.success("Updated successfully!")
        }else if(res.data.code===201){
          toast.error("error are coming")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
      
      } 
    useEffect(()=>{
      getAll()
    },[])
  return (
    <>
    <Toptag />
    <ToastContainer />
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"20px",fontWeight:"400",color:"grey"}}>User Profile Details</Grid>
      {dis?<Grid item><button className='newBtn'  onClick={()=>{setDis(false)}}>Edit</button></Grid>:null}  
    </Grid>
    <hr></hr>
    
    <Grid container spacing={2}>
     <Grid xs={12} sm={12} md={6} lg={4} item>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
                {obj.profile_pic==='blank_pic.png'? (
                  <img src="/images/blank_pic.png" style={{ height: "150px", width: "150px", borderRadius: "5px" }} alt="" />
                  ) : (
                    <img src={`https://admin.babytogs.app/uploads/${obj.profile_pic}`} style={{ height: "150px", width: "150px", borderRadius: "5px" }} alt="" />
                    )}
              </Grid>
            </Grid>
          </Grid><Grid xs={12} sm={12} md={6} lg={8} item>
              <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>Name</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={dis?true:false}  value={obj.name} onChange={addata} name="name" id="name" className="detailBarInput" />
                </Grid>
                <Grid item>Email</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled placeholder={obj.email} className="detailBarInput" />
                </Grid>
                <Grid item>Status</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled placeholder={obj.isActive === true ? ('Active User') : ('Blocked User')} className="detailBarInput" />
                </Grid>
                <Grid item>Selected Region</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled  placeholder={obj.region}  className="detailBarInput" />
                </Grid>
                {!dis?<Grid item><button className='newBtn' onClick={updateUser}>Update</button></Grid>:null}  
              </Grid>
            </Grid>
    </Grid>

    </BackPaper>
    </>
  )
}

export default UserDetail