import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import './profile.css';
import Toptag from '../../components/topTag/Toptag';
import { AddToPhotos, ConnectingAirportsOutlined, Publish, TwoKPlus } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Grid, Paper } from '@mui/material';


const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  marginBottom:"5px",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper =  styled(Paper)({
  padding:"10px",
  height:"440px",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
export default function Profile() {
 

  const navigate=useNavigate();
  const [dis, setDis] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [detail,setDetail]=useState({name:'',email:''})
  const [image,setImage]=useState("")
  const [image2,setImage2]=useState("")
  const [data, setData]=useState([])
  const [error, setError]=useState(false)
  

  const onButtonClick = () => {
    console.log( 'file')

  };

  const editFunction = () => {
    setDis(false)
  }

 

 const inputEvent = (e) =>{
  setDetail({
    ...detail,
    [e.target.name] : e.target.value.trim(),
 })
 }
  const imageChange=(e)=>{
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    if (file.type==='image/jpeg' || file.type==='image/png') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }else if (file.type!='image/jpeg' || file.type!='image/png'){
      setImage2("")
      setError(true)
    }else{
      setError(true)
    }
    
  }
  useEffect(() => {
    setIsLoading(true);
    getData()
  },[])
  const getData = async(event) => {
    axios.get('https://admin.babytogs.app/admin/get_details',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
    if (res.data.code===200) {
      sessionStorage.setItem('image',res.data.profile_pic)
      setImage(null)
      setDetail(res.data)
       setIsLoading(false);
        }else if(res.data.code===203){
          localStorage.clear('token');

          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
        
		}).catch((err) => {
			console.log(err, "err")
			window.alert(err.message)
		})
  }
  const updateAdminProfile = (event) => {
    event.preventDefault();
    const formData = new FormData()
    formData.append("name",detail.name)
    formData.append("email",detail.email)
    formData.append("profile_pic",image2)
    axios.post('https://admin.babytogs.app/admin/edit_details',formData,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
      if (res.data.code===200) {
        sessionStorage.setItem('image',res.data.profile_pic)
          toast.success('Update  successfully !', {
              position: toast.POSITION.TOP_RIGHT
          });
          
          setDis(true)
          navigate('/profile')
      }else if(res.data.code===205){
          toast.error('please edit try again!', {
              position: toast.POSITION.TOP_RIGHT
          });
      }else if(res.data.code===203){
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate("/")
        toast.info("Session Expired Please login again!!")
      }
      
  }).catch((err) => {
    console.log(err, "err")
    window.alert(err.message)
  })
    setDis(false)
  }

  return (
    <>
      {
        isLoading
          ?
          <Loader />
          :
          <div className='container-fluid'>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item style={{fontSize:"20px",fontWeight:"400",color:"grey"}}>Admin Profile</Grid>
                <Grid item>
                {dis===true?<button className='newBtn'  onClick={editFunction}>Edit</button>:null}
                </Grid>
              </Grid>
            </NavPaper>
            <BackPaper>
            <Grid container  spacing={4}>
              <Grid xs={12} sm={12} md={6} lg={3} item>
                <Grid container spacing={2} direction="column" justifyContent="space-evenly" alignItems="center">
                <Grid item>
 
                
                {image?
                 <img src={image}  alt="./images/blank_pic.png" className='imgCont1'/>
                    :(
                      detail.image===null?<img src='/images/blank_pic.png' alt="balnk_image" className='imgCont1' />:
                      <img src={`https://admin.babytogs.app/uploads/${detail.profile_pic}`} name='profile_pic' onClick={onButtonClick} className='imgCont1' alt="" />
                    )
                    }
                </Grid>
                <Grid item>
                {!dis?<div className="image-upload">
                <label htmlFor="image">
                <Tooltip title="Change your profile pic"><AddToPhotos  style={{fontSize:"30px",color:"grey",cursor:"pointer"}}/></Tooltip>
                </label>

                <input id="image" onChange={imageChange} type="file" accept="image/*"/>
                </div>:null}
                </Grid>
                {error?<><Grid item className='fileErr'>Invalid file format. Only JPG, PNG, and other image formats are accepted</Grid>
                <Grid item><button  className='newBtn' onClick={()=>setError(false)}>Ok</button></Grid></>:null}
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={9} item>
                  <Grid  container spacing={1} direction="column" justifyContent="space-evenly" alignItems="flex-start">
                    <Grid item><label className='myLabel'>Name</label></Grid>
                    <Grid item style={{width:"100%"}}><input type="text" name="name"  disabled={dis?true:false} value={detail.name}  id="name"  onChange={inputEvent} className="searchBarInput" /></Grid>
                    <Grid item><label className='myLabel'>Email</label></Grid>
                    <Grid item style={{width:"100%"}}><input  type="text" name="email" disabled={true} placeholder={detail.email}  id="email"  onChange={inputEvent} className="searchBarInput" /></Grid>
                  </Grid>
              </Grid>
            </Grid>
            

            <Grid container  direction="row" justifyContent="center"   alignItems="center">
              <Grid item>
              {dis===false && error===false?<div className="SubmitBtnBox">
                <Tooltip title="Click to Update profile" >
                  <button  className='updateDataBtn' onClick={updateAdminProfile} >
                    Update
                  </button>
                </Tooltip>
              </div>:null
              }
              </Grid>
            </Grid>
              
            </BackPaper>
          </div>

      }
    </>

  )
}
