import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import ChangePass from './pages/profile/ChangePass';
import UserDetail from './pages/user/UserDetail';
import User from './pages/user/User';
import Description from './pages/description/Description';
import ItemImage from './pages/item/ItemImage';
import Faq from './pages/content/Faq';
import Default from './pages/item/Default';
import AddNew from './pages/item/AddNew';
import Region from './pages/item/Region';
import Login  from './pages/login/Login'
import FaqDeta from './pages/content/FaqDeta';
import AddnewF from './pages/content/AddnewF';
import ItemDe from './pages/item/ItemDe';
import Privacy from './pages/content/Privacy';
import Term from './pages/content/Term';



export default  [
    { path: "/" ,  Component: <Home/> },
    { path: "/profile" ,    Component: <Profile/> },
    { path: "/change_Password" ,    Component: <ChangePass/> },
    { path: "/users" ,    Component: <User/> },
    { path: "/home/users" ,  Component: <User/> },
    { path: "/home/country-region" ,  Component: <Region/> },
    { path: "/item-images" ,  Component: <ItemImage/> },
    { path: "/default-size" ,  Component: <Default/> },
    { path: "/faq" ,  Component: <Faq/> },
    { path: "/privacy-policy" ,  Component: <Privacy/> },
    { path: "/terms-&-conditions" ,  Component: <Term/> },
    { path: "/add-new-faq" ,   Component: <AddnewF/> },
    { path: "/faq/details" ,   Component: <FaqDeta/> },
    { path: "/description" ,  Component: <Description/> },
    { path: "/users/details" ,   Component: <UserDetail/> },
    { path: "/item-images/details" ,   Component: <ItemDe /> },
    { path: "/add-size" ,   Component: <AddNew/> },
    { path: "/country-region" ,   Component: <Region/> },
    { path: "*" ,  Component: <Home/> },
    // { path: "/login-account" ,  Component: <Login /> },

]
