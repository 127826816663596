import { AddToPhotos } from '@mui/icons-material'
import { Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "../../pages/user/userDetail.css"


const BackPaper =  styled(Paper)({
  height:"610px",
  borderRadius:"0",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ItemDe = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [dis, setDis] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [det,setDet] = useState([]);
  const [detail,setDetail]=useState({name:''})
  const [item_image, setItemImage] = useState("");
  const [image, setImage] = useState("");

  
  const editFunction = () => {
    setDis(false)
  }
  const getAll =()=>{
    axios.get(`https://admin.babytogs.app/admin/detail_item/${location.state.id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
            setIsLoading(false)
            setDetail(res.data.data[0])  
        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 
    useEffect(()=>{
        setIsLoading(true)
      getAll()
    },[])


    const inputEvent = (e) =>{
        setDetail({
          ...detail,
          [e.target.name] : e.target.value.trim(),
       })
       }
        const imageChange=(e)=>{
         setItemImage(e.target.files[0])
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
          }
          
        }
    const updateProfile = (event) => {
        event.preventDefault();
        const formData = new FormData()
        formData.append("name",detail.name)
        formData.append("item_image",item_image)
        axios.post(`https://admin.babytogs.app/admin/edit_item/${location.state.id}`,formData,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
          if (res.data.code===200) {
             
              setDis(true)
              toast.success('Update  successfully !', {
                position: toast.POSITION.TOP_RIGHT
            })
          }else if(res.data.code===205){
              toast.error('please edit try again!', {
                  position: toast.POSITION.TOP_RIGHT
              });
          }else if(res.data.code===203){
            localStorage.clear('token');
            sessionStorage.clear('token');
            navigate("/")
            toast.info("Session Expired Please login again!!")
          }
          
      }).catch((err) => {
        console.log(err, "err")
        window.alert(err.message)
      })
        setDis(false)
      }
    
  return (
    <>
    {isLoading?
   <Loader />    :
<>
<Toptag />
    <ToastContainer />
    <BackPaper>
    <Grid container  direction="row"  justifyContent="space-between" alignItems="center">
      <Grid item style={{fontSize:"23px",fontWeight:"500"}}>Item Details</Grid>
      <Grid item>{dis===true?<button className='newBtn'  onClick={editFunction}>Edit</button>:null}</Grid>
    </Grid>
    <hr></hr>
    
    <Grid container spacing={2}>
      <Grid xs={12} sm={12} md={6} lg={4} item >
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
              {image?
                 <img src={image}  alt="./images/blank_pic.png" style={{ height: "80px", width: "80px", borderRadius: "5px" }}/>
                    :
                    <img src={`https://admin.babytogs.app/uploads/${detail.pic}`} style={{ height: "80px", width: "80px", borderRadius: "5px" }} alt="" />
                    }
              </Grid>
              {/* <Grid item>
                {!dis?<div className="image-upload">
                <label for="item_image">
                <Tooltip title="Change item image"><AddToPhotos  style={{fontSize:"20px",color:"grey",cursor:"pointer",marginTop:"10px"}}/></Tooltip>
                </label>

                <input id="item_image" onChange={imageChange} type="file" accept="image/*"/>
                </div>:null}
                </Grid> */}
            </Grid>
          </Grid><Grid xs={12} sm={12} md={6} lg={8} item>
              <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>Item Name</Grid>
                <Grid item style={{ width: "100%" }}>
                  <input type="text" disabled={dis?true:false} value={detail.name} id="name" name="name" onChange={inputEvent} className="detailBarInput" />
                </Grid>
              </Grid>
            </Grid>
    </Grid>
        <Grid container  direction="row" justifyContent="center"   alignItems="center">
              <Grid item>
              {dis===false?<div className="SubmitBtnBox">
                <Tooltip title="Click to Update profile" >
                  <button  className='updateDataBtn' onClick={updateProfile} >
                    Update
                  </button>
                </Tooltip>
              </div>:null
              }
              </Grid>
            </Grid>
    </BackPaper></>
}
    
    </>
  )
}

export default ItemDe