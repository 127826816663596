import React, { useCallback, useEffect, useState } from 'react'
import { AddToPhotos, AppRegistration, Close, DeleteOutline, DriveFolderUpload, NoteAdd, RemoveRedEye } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, Grid, Paper, Slide, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, useMediaQuery } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import "./item.css"
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import { useTheme } from '@mui/system'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BackPaper =  styled(Paper)({
  height:"600px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const ItemImage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [all,setAll]= useState([])
  const [obj,setObj] = useState({name:'',pic:''})
  const [image,setImage]=useState("")
  const [error2,setError2] = useState(false)
  const navigate = useNavigate();
  const [deleteopen, setDelOpen] = React.useState(false);
  const [id,setId] = useState()
  const [editimage,setEditImage] = useState(false)
  const [crop, setCrop] = useState({
    unit: 'px',
    width: '240',
    height: '240',
    aspect: 16 / 9,
    x: 5,
    y: 5
  });

  const getAll =()=>{
    axios.get('https://admin.babytogs.app/admin/get_item',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
    if(res.data.code===200){
          setIsLoading(false)
         setAll(res.data.data)   
        }else if(res.data.code===201){
            toast.error("error are coming for fetching images")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
    })
    } 
  useEffect(()=>{
    setIsLoading(true)
    getAll()
  },[])

  const addata = (e) => {
    setObj({
        ...obj,
        [e.target.name] : e.target.value.trim(),
     })
  }

  const addDataimage = (e) =>{
    if(e){
      setEditImage(true)
      setObj({
          ...obj,
          pic:e.target.files[0]
       })
      const file = e.target.files[0];
      if (file.type==='image/jpeg' || file.type==='image/png') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImage(reader.result);
        };
      }else if (file.type!='image/jpeg' || file.type!='image/png'){
        setError2(true)
      }else{
        setError2(true)
      }

    }else{
      setEditImage(false)
    }
    
    }
    
  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };
const addData = (event) =>{
    event.preventDefault();
    if(obj.name === '' && obj.pic === ''){
      toast.error("Please add all values!!",{
        position: toast.POSITION.TOP_RIGHT
    })
    }else if(obj.pic === ''){
      toast.error("Please select one image!",{
        position: toast.POSITION.TOP_RIGHT
    })
    }else if(obj.name === ''){
      toast.error("Please enter new name!!",{
        position: toast.POSITION.TOP_RIGHT
    })
    }else{
    const formData = new FormData()
    formData.append("name",obj.name)
    formData.append("pic",obj.pic)
    axios.post('https://admin.babytogs.app/admin/add_item',formData,{headers:{token:sessionStorage.getItem('token')}}).then((res)=>{
        if(res.data.code===200){
          console.log(res)  
          getAll();
            setObj({name:""});
            setImage()
            setObj({name:''})
            toast.success("Item add successfully",{
              position: toast.POSITION.TOP_RIGHT
            })  
        }else if(res.data.code===201){
            toast.error("Item is already exist!",{
                position: toast.POSITION.TOP_RIGHT
            })
            getAll()
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
    })
    }
    
}
const deleteImage = (id) =>{
  axios.delete(`https://admin.babytogs.app/admin/delete_item/${id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
      if(res.data.code===200){
        getAll()
        setDelOpen(false);
        toast.success("Deleted Successfully!")
      }else if(res.data.code===201){
        toast.error("Error While Deleting Size")
      }else if(res.data.code===203){
        localStorage.clear('token');
        sessionStorage.clear('token');
        navigate("/")
        toast.info("Session Expired Please login again!!")
      }
    })
}

const closeEdit = () =>{
  setEditImage(false)
  const reader = new FileReader();
      reader.readAsDataURL(obj.item_image);
      reader.onloadend = () => {
        setImage(reader.result);
      };
}
const editmage = (c) =>{
setCrop(c)
}

const onCropComplete = (croppedArea, croppedAreaPixels) => {
  const canvas = document.createElement('canvas');
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;
  const ctx = canvas.getContext('2d');
  const img = document.createElement('img');
  img.src = image;
  ctx.drawImage(
    img,
    croppedArea.x,
    croppedArea.y,
    croppedArea.width,
    croppedArea.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );
  canvas.toBlob((blob) => {
    setObj({ ...obj, item_image: blob });
  });
};
  return (
    <>
    {isLoading?
   <Loader />:
   <>
     <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid  container  direction="row"   justifyContent="space-between" alignItems="center">
        <div></div>
         <div>
         <Grid container spacing={3} direction="row"  justifyContent="flex-start" alignItems="center">    
          <Grid item><input type="text" name="name" placeholder='Enter new name' value={obj.name} onChange={addata} id="name" className="searchBarInput" /></Grid>  
         {image?<>
         <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>Selected Image</Grid>
          
          <Grid item>
          <img src={image}  alt="new image" className='selectedIMage'/>
          </Grid>
          </>
          :null}
          <Grid item className="image-upload">
          <label htmlFor="pic">
                <Tooltip title="Select one item image"><AddToPhotos  style={{fontSize:"30px",color:"#a5a0a0",cursor:"pointer"}}/></Tooltip>
          </label>
          <input id="pic" name="pic" onChange={addDataimage} type="file" accept="image/*"/>
          </Grid>  
          
          <Grid item><button className='newBtn' onClick={addData}>Add</button></Grid>  
         </Grid>
        
         </div>   
    </Grid>
    </NavPaper>
    
    <BackPaper>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow className='tableHead'>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {all.map((element,index)=>{
            let date = new Date(element.created_at);
            let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
            let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
              datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
              date.toISOString().substring(0, 10).split("-")[0];
            return(
          <TableRow key={index+1} className='tableData'>
            <TableCell>
              {element.pic===''?
                <img src="/images/noImage.jpg" alt="item_image" style={{width:"60px",height:"60px",borderRadius:"50px",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}/>
              :
              <img src={`https://admin.babytogs.app/uploads/${element.pic}`} alt="item_image" style={{width:"60px",height:"60px",borderRadius:"50px",boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}/>
              }
            </TableCell>
            <TableCell>{element.name}</TableCell>
            <TableCell align="center">{newDate}</TableCell>
            <TableCell align="right">
            <Grid  container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
               <Grid item>
                 <Tooltip title="view item" >
                 <NavLink to='/item-images/details' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc'   /></NavLink>
                 </Tooltip>
               </Grid>
              <Grid item>
              <DeleteOutline className="userListDelete" onClick={()=>{handleDeleteOpen(element._id)}}/>
              </Grid>
            </Grid>
            </TableCell>
          </TableRow>
            )
        })}    
        </TableBody>
      </Table>
      <Dialog open={deleteopen}  fullScreen={fullScreen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this item?"}
             
              </DialogTitle>
              <div style={{marginLeft:"20px",fontSize:"14px",color:"grey"}}>
              Note:- Deleting this image and its associated data will have an impact on your app. Please consider whether this is the appropriate action to take before proceeding with the deletion.
              </div>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteImage(id)}}>Delete</Button>
              </DialogActions>
            </Dialog>

        

            <Dialog open={editimage}  TransitionComponent={Transition}  fullScreen={fullScreen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              
              <div style={{padding:"40px"}}>
              <DialogContentText id="alert-dialog-description" style={{marginBottom:"10px"}}>
              {error2?<><div className='fileErr' style={{fontSize:"22px"}}>Invalid file format. Only JPG, PNG, and other image formats are accepted</div></>:<>Edit or crop your image</>}
              </DialogContentText>
               <ReactCrop crop={crop} onChange={editmage} locked={true} onComplete={onCropComplete}>
              <img src={image}  />
              </ReactCrop>
              </div>
              
              <DialogActions>
                {!error2?<Button onClick={closeEdit}>Submit</Button>:
                <Button onClick={()=>{setEditImage(false);setError2(false);setObj({item_image:""});setImage("");}}>Ok</Button>}
              </DialogActions>
            </Dialog>
    </BackPaper>
   </>  
  }
  
    </>
  )
}

export default ItemImage