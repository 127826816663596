import React, {  useState } from 'react';
import './changePass.css'
import axios from 'axios';
import './profile.css';
import Toptag from '../../components/topTag/Toptag';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material/';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';




const BackPaper =  styled(Paper)({
    padding:"20px",
    height:"540px",
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
  })
export default function ChangePass() {
   
    const [inputPass1, setInputPass1] = useState('password');
    const [inputPass2, setInputPass2] = useState('password');
    const navigate = useNavigate();

    const [one, setOne] = useState({oldPassword:'',newPassword:'',retypePassword:''});

    const handleKeyDown = e => {
        console.log(e.target.value)
        if (e.target.value === '') {
            if (e.key === " ") {
                e.preventDefault();
            }
        }
    };

    const inputEvent = (e) => {
        const { id, value } = e.target;
        setOne((prevalue) => {
            return {
                ...prevalue,
                [id]: value
            };
        });
    };

  const submitVal = async(event) => {
    event.preventDefault();
    axios.post('https://admin.babytogs.app/admin/change_password', one,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if (res.data.code===200) {
            setOne({oldPassword:"",newPassword:"",retypePassword:""})
            toast.success('Change password successfully !', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if(res.data.code===205){
            toast.info('Please Enter All Feilds', {
                position: toast.POSITION.TOP_RIGHT
            });
        }else if(res.data.code===203){
            localStorage.clear('token');
            sessionStorage.clear('token');
            navigate("/")
            toast.info("Session Expired Please login again!!")
          }
          else if(res.data.code===201){
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
          }
        
		}).catch((err) => {
			console.log(err, "err")
			window.alert(err.message)
		})
  }

    const changeInput1 = () => { if (inputPass1 === 'password') {  setInputPass1('text') } else { setInputPass1('password')}}
    const changeInput2 = () => { if (inputPass2 === 'password') {  setInputPass2('text') } else { setInputPass2('password')}}
    

    return (
        <>
        <Toptag />
            <ToastContainer />
            <BackPaper>
            <Grid container spacing={3} style={{marginTop:"30px"}}>
                    <Grid xs={12} sm={12} md={6} lg={4} item>
                    <Grid container spacing={3} direction="column" justifyContent="space-evenly"  alignItems="center">
                        <Grid item><label className='labelCont'>Old Password</label></Grid>
                        <Grid item><label className='labelCont'>New Password</label></Grid>
                        <Grid item><label className='labelCont'>Re-type Password</label></Grid>
                    </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={8} item>
                    <Grid container spacing={3} direction="column" justifyContent="space-evenly"  alignItems="center">
                         <Grid style={{width:"100%"}} item>
                        <input type="password" name="oldPassword" value={one.oldPassword}   id="oldPassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                        <Grid style={{width:"100%"}} item>
                        <input type="password" name="newPassword"   value={one.newPassword} id="newPassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                        <Grid style={{width:"100%"}} item>
                        <input type="password" name="retypePassword"  value={one.retypePassword}   id="retypePassword" className="searchBarInput" onChange={inputEvent}/>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row"  style={{marginTop:"10px"}} justifyContent="center" alignItems="center">
                    <Grid item>
                    <Tooltip title="Click to Update Password" >
                    <button className='updateBut1' onClick={submitVal}>Update Password</button>
                    </Tooltip>
                    </Grid>
                </Grid>



               
                
            </BackPaper></>
        
    )
}
