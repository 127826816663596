import { Block, DeleteOutline, RemoveRedEye } from '@mui/icons-material'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Slide, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import countryCodes  from "country-codes-list"
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Toptag from '../../components/topTag/Toptag'
import "./item.css"
import { useEffect, useState } from 'react'
import { getName } from 'country-list'
import Loader from '../../components/loader/Loader'



const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper =  styled(Paper)({
  borderRadius:"0",
  height:"580px",
  overflow:"auto",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Region = () => {
  const [all,setAll] = useState([]);
  const [reg,setReg] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteopen, setDelOpen] = useState(false);
  const [id,setId] = useState()


 
  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };
  const handleChange = (e) => {
    setReg(e.target.value)
  };
  const handleDelete = () => {
    setDelOpen(false);
  };
  const myCountryCodesObject = countryCodes.customList()
  
  const getAll =()=>{
    axios.get('http://208.68.37.50:4028/admin/get_region',{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          setIsLoading(false)
         setAll(res.data.data)  
        }else if(res.data.code===201){
          toast.error("error are coming for fetching images")
        }
        else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
    } 
    useEffect(()=>{
      setIsLoading(true)
      getAll()
    },[])
  const deleteUnit = (id) =>{
    axios.delete(`http://208.68.37.50:4028/admin/delete_region/${id}`,{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          getAll()  
          setDelOpen(false);
          toast.success("Deleted Successfully!")
        }else if(res.data.code===201){
          toast.error("error are coming")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
  }
  const addRegion = () =>{
    axios.post('http://208.68.37.50:4028/admin/add_new_region',{country_region:reg},{headers:{token:sessionStorage.getItem('token')}}).then((res) => {
        if(res.data.code===200){
          getAll();
          setReg()
          toast.success("Add Successfully!")
        }else if(res.data.code===205){
          toast.error("Please Enter Country Region")
        }else if(res.data.code===201){
          toast.info("You Already Added This One")
        }else if(res.data.code===203){
          localStorage.clear('token');
          sessionStorage.clear('token');
          navigate("/")
          toast.info("Session Expired Please login again!!")
        }
      })
  }
  const nav = (region) =>{
  localStorage.setItem('region',region)
  navigate("/default-size")
  }
  
  
  return (
    <>
    {isLoading?<Loader />:
    <>
     <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid container spacing={3} direction="row"  justifyContent="space-between"  alignItems="center">
      <Grid item>
      <Grid container spacing={2} direction="row" justifyContent="flex-start"  alignItems="center">
        <Grid item ></Grid>
      </Grid>
      </Grid>
      <Grid item>
      <Grid item>
      <Grid container spacing={2} direction="row" justifyContent="flex-start"  alignItems="center">
      <Grid item style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>
      {reg ? `Selected Country: ${getName(reg)}` : "No country selected"}
      </Grid>
      <Grid item style={{fontSize:"17px",fontWeight:"bold"}}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
      <InputLabel id="demo-simple-select-standard-label" className="MuiInput">Choose One Region</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard-label"
          label="Choose One Region"
          value={reg}
          onChange={handleChange}
        >
         {Object.keys(myCountryCodesObject).map((element,index)=>(
          <MenuItem key={index+1}  value={element}>{element}</MenuItem>
         ))} 
        </Select>
      </FormControl>
      </Grid>
      <Grid item><button className='newBtn' onClick={addRegion}>Add</button></Grid>
      </Grid>
      </Grid>
      </Grid>
    </Grid>
    </NavPaper>
    <BackPaper>
    <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Country Region</TableCell>
            <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {all.map((element,index)=>{
              let date = new Date(element.created_at);
              let datearr = [ "Jan", "Feb", "March","Apr", "May", "Jun","July","Aug","Sept","Oct","Nov","Dec", ];
              let newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] +" " +
                date.toISOString().substring(0, 10).split("-")[0];
              return(
            <TableRow key={index+1}>
            <TableCell>{newDate}</TableCell>
            <TableCell>{element.country_region}</TableCell>
            <TableCell align="right">
            <Grid  container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
               <Grid item>
                 <Tooltip title="view profile" >
                 <RemoveRedEye className='visibilityIc' onClick={()=>{nav(element.country_region)}} />
                 </Tooltip>
               </Grid>
              <Grid item>
              <DeleteOutline className='Icon' onClick={()=>{handleDeleteOpen(element._id)}}/>
              </Grid>
            </Grid>
            </TableCell>
            </TableRow>
              )
            })}
          
          </TableBody>
        </Table>
        <Dialog open={deleteopen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
              {"Are you sure want to delete this region?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDelete}>Cancel</Button>
                <Button onClick={()=>{deleteUnit(id)}}>Delete</Button>
              </DialogActions>
            </Dialog>
    </BackPaper>
    </>
    }
   
 
    </>
  )
}

export default Region


